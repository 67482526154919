// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { useI18n } = require('nordic/i18n');
const { Text, Title } = require('@andes/typography');

const configs = require('./configs');

const CredentialsHeader = ({ children, siteId = 'MLA' }) => {
  const { i18n } = useI18n();

  const { title, description } = configs[siteId || 'MLA'](i18n);

  return (
    <header className="credentials-header">
      <div className="certificate-container">
        <div className="credentials-header-texts">
          <Title className="credentials-header-title" component="h1" size="l">
            {title}
          </Title>
          <Text className="credentials-header-description" component="p" size="s">
            {description}
          </Text>
        </div>
        <div className="credentials-header-cardUser">{children}</div>
      </div>
    </header>
  );
};

CredentialsHeader.propTypes = {
  children: PropTypes.node,
  siteId: PropTypes.string,
};

module.exports = CredentialsHeader;
