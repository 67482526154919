const React = require('react');
const PropTypes = require('prop-types');

const { useEffect, useState } = React;

const Page = require('nordic/page');

const Commons = require('@components/commons');
const CredentialsBody = require('@components/credentials-body');
const CredentialsHeader = require('@components/credentials-header');
const UserInfoCard = require('@components/user-info-card');

const { getResellerInfo } = require('@services/user');

const Certificate = (props) => {
  const { nickname = '', siteId, isMobile = false } = props;

  const [data, setData] = useState({});

  const validateHash = (hash) => {
    const uuidValidate =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return uuidValidate.test(hash);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uuidIsValid = validateHash(nickname);

        if (!uuidIsValid) {
          window.location.href = '/revendedores';

          return;
        }

        const resp = await getResellerInfo(nickname);

        if (Object.keys(resp).length === 0) {
          window.location.href = '/revendedores';
        }

        setData(resp);
      } catch (err) {
        window.location.href = '/revendedores';
      }
    };

    fetchData();
  }, [nickname]);

  return (
    <Page name="certificate" state={props}>
      <Commons {...props} name="certificate" />
      {isMobile ? (
        <>
          <CredentialsHeader siteId={siteId} />
          <section className="certificate-container certificate-userInfoCardPosition">
            <UserInfoCard siteId={siteId} user={data} />
          </section>
        </>
      ) : (
        <CredentialsHeader siteId={siteId}>
          <UserInfoCard siteId={siteId} user={data} />
        </CredentialsHeader>
      )}
      {data?.products?.length && (
        <CredentialsBody data={data} isMobile={isMobile} nickname={nickname} siteId={siteId} />
      )}
    </Page>
  );
};

Certificate.propTypes = {
  company: PropTypes.string,
  deviceType: PropTypes.string,
  imagesPrefix: PropTypes.string,
  isMobile: PropTypes.bool,
  lowEnd: PropTypes.bool,
  nickname: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  translations: PropTypes.shape({}),
};

module.exports = Certificate;
