// i18nLocalesOverride:['es-AR','es-MX','pt-BR']

const React = require('react');
const PropTypes = require('prop-types');

const { Image } = require('nordic/image');
const { useI18n } = require('nordic/i18n');

const { Text } = require('@andes/typography');

const { formatDateWithoutDay } = require('@utils/date');

const UserInfoCard = ({ siteId = 'MLA', user = {} }) => {
  const { i18n } = useI18n();

  const contactData = [
    user?.city &&
      user?.state && {
        imageSrc: 'certificate/icon-map.svg',
        text: `${user?.city} - ${user?.state}`,
      },
    user?.phoneNumber && {
      imageSrc: 'certificate/icon-phone.svg',
      text: user?.phoneNumber,
    },
    user?.email && {
      imageSrc: 'certificate/icon-email.svg',
      text: user?.email,
    },
  ].filter(Boolean);

  return (
    <div className="user-info-card">
      <ul>
        <li className="user-info-card-item">
          {user.thumbnail ? (
            <Image lazyload className="user-info-card-avatar" src={user.thumbnail} />
          ) : (
            <span className="user-info-card-avatar user-info-card-placeholder-avatar" />
          )}
          <Text className="user-info-card-details" component="p">
            <Text className="user-info-card-details-userName" component="strong">
              {user.fullName}
            </Text>
            <Text
              className="user-info-card-details-since"
              color="secondary"
              component="small"
              size="xs"
            >
              {i18n.gettext(
                'Revendedor desde {0}',
                formatDateWithoutDay(user.resellerRegistrationDate, siteId),
              )}
            </Text>
          </Text>
        </li>
        {contactData?.map((item) => (
          <li key={item?.text} className="user-info-card-item">
            <Image lazyload className="user-info-card-icon" src={item?.imageSrc} />
            {item?.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

UserInfoCard.propTypes = {
  siteId: PropTypes.string,
  user: PropTypes.shape({
    city: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    resellerRegistrationDate: PropTypes.string,
    state: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
};

module.exports = UserInfoCard;
